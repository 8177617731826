import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import CustomDND from "../../../component/common/Custom-DND";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { apiGenerator } from "../../../util/functions";

const HomeCountry = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.Home.countryGet, (res) => {
      const updatedList = res?.data?.map((i, index) => {
        return { ...i, order: index + 1 };
      });
      setList(updatedList);
    });
  }, [refresh]);
  useEffect(() => {
    if (isOpenModal) {
      const apiCountry = { ...CONSTANTS.API.Country.getAll };
      apiCountry.endpoint = apiCountry.endpoint + "?limit=300";
      API.sendRequest(apiCountry, (res) => {
        const country = res?.data?.rows?.map((i) => {
          return {
            ...i,
            label: i?.name,
            value: i?.id,
            id: i?.id,
          };
        });
        CONSTANTS.FORM_FIELD.EDIT_HOME_COUNTRY.find(
          (el) => el?.id === "countryId"
        ).option = country;
      });
    }
  }, [isOpenModal]);

  const handleEdit = (data) => {
    const country = { ...CONSTANTS.API.Home.countryUpdate };
    const apiCountry = apiGenerator(country, { id: data?.countryId });
    const payload = {
      index: selectedData?.index ?? selectedData?.order,
    };
    API.sendRequest(
      apiCountry,
      (res) => {
        setIsOpenModal((prv) => !prv);
        setRefresh((prv) => !prv);
      },
      payload,
      "Edit Country Successfully!!!"
    );
  };

  return (
    <>
      <Card className="my-5">
        <h1 className="text-2xl font-semibold">Countries</h1>
        <CustomDND
          data={list}
          setRefresh={setRefresh}
          editApi={CONSTANTS.API.Home.countrySwapIndex}
          payloadIdKey={"id"}
          grid={4}
          height={200}
          component={(res) => {
            return (
              <Card
                cover={
                  <img alt="example" src={res?.image?.imageUrl} height={190} />
                }
                bodyStyle={{ padding: "8px" }}
                actions={[
                  <EditOutlined
                    key="edit"
                    src={res?.image[1]?.imageUrl || null}
                    onClick={() => {
                      setSelectedData(res);
                      setIsOpenModal((prv) => !prv);
                    }}
                  />,
                ]}
              >
                <h2 className="truncate text-center text-lg" title={res?.name}>
                  {res?.name}
                </h2>
              </Card>
            );
          }}
        />
      </Card>
      {isOpenModal ? (
        <ModalFormCreator
          open={isOpenModal}
          onCreate={handleEdit}
          onCancel={() => {
            setIsOpenModal((prev) => !prev);
          }}
          name={"Edit Home Country"}
          menuFields={CONSTANTS.FORM_FIELD.EDIT_HOME_COUNTRY}
          loading={API.isLoading}
          formData={{ ...selectedData, countryId: selectedData?.id }}
        />
      ) : null}
    </>
  );
};

export default HomeCountry;
